import React, { useState } from 'react';
import Header from "./component/Header";
import Sidebar from "./component/Sidebar";
import NewsGrid from "./component/NewsGrid";
import './App.css';  // Your custom CSS
import WeatherCard from './component/WeatherCard';

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
      <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      <div className="app-container">
        {/* Left Sidebar */}
        <div className="sidebar">
          <Sidebar />
        </div>

        {/* Center content - News Grid */}
        <div className="news-grid-container">
          <NewsGrid />
        </div>

        {/* Right Sidebar - Weather Card */}
        <div className="weather-card">
          <WeatherCard />
        </div>
      </div>
    </div>
  );
}

export default App;
