import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';

const WeatherCard = () => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState('');
  const [fromCurrency, setFromCurrency] = useState('USD');
  const [toCurrency, setToCurrency] = useState('EUR');
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [conversionError, setConversionError] = useState(null);

  // Handle currency conversion
  const handleCurrencyConversion = async () => {
    if (amount && parseFloat(amount) >= 1) {
      try {
        // Replace with your own API URL and key for conversion
        const rate = await fetchConversionRate(fromCurrency, toCurrency);
        const result = (parseFloat(amount) * rate).toFixed(2);
        setConvertedAmount(result);
        setConversionError(null);
      } catch (error) {
        setConversionError('Error fetching conversion data. Please try again later.');
      }
    } else {
      setConversionError('Please enter a valid amount.');
    }
  };

  // Fetch conversion rate (example API, replace with your actual one)
  const fetchConversionRate = async (fromCurrency, toCurrency) => {
    const response = await fetch(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`);
    const data = await response.json();
    return data.rates[toCurrency];
  };

  useEffect(() => {
    const lat = 40.7128; // Example latitude (New York)
    const lon = -74.0060; // Example longitude (New York)
    const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
  
    axios
      .get(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}`)
      .then((response) => {
        setWeather(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching weather data', error.response ? error.response.data : error.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="weather-sidebar">
      <Card className="weather-card">
        <Card.Body className="weather-card-body">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p>Loading weather data...</p>
            </div>
          ) : weather ? (
            <>
              <div className="weather-header">
                <Card.Title className="weather-title">Sample Weather in {weather.name}</Card.Title>
                <Card.Subtitle className="weather-date">{new Date().toLocaleDateString()}</Card.Subtitle>
              </div>
              <div className="weather-main">
                <div className="weather-icon">
                  <img
                    src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}.png`}
                    alt={weather.weather[0].description}
                    className="weather-icon-img"
                  />
                </div>
                <div className="weather-info">
                  <p className="weather-description">
                    <strong>{weather.weather[0].description}</strong>
                  </p>
                  <p className="weather-temp">
                    {Math.round(weather.main.temp - 273.15)}°C
                  </p>
                </div>
              </div>
              <div className="weather-footer">
                <p className="weather-details">
                  <strong>Humidity:</strong> {weather.main.humidity}% | <strong>Wind Speed:</strong> {weather.wind.speed} m/s
                </p>
              </div>
            </>
          ) : (
            <p>No weather data available at the moment.</p>
          )}
        </Card.Body>
      </Card>

      <br />
      <Card className="custom-card">
        <Card.Body className="custom-card-body">
          <Card.Title className="Title-text">Currency Converter</Card.Title>

          <div className="text-center mb-3">
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                className="custom-form-control"
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                style={{ width: '80%', margin: '0 auto' }}
              />
            </Form.Group>
          </div>

          <Row className="mb-3 d-flex justify-content-center">
            <Col xs={4} sm={3}>
              <Form.Group controlId="fromCurrency">
                <Form.Label>From</Form.Label>
                <Form.Control
                  as="select"
                  value={fromCurrency}
                  onChange={(e) => setFromCurrency(e.target.value)}
                  className="custom-form-control"
                >
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="NGN">NGN</option>
                  {/* Add more currencies here */}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={2} sm={1} className="d-flex align-items-center justify-content-center">
              <span>To</span>
            </Col>

            <Col xs={4} sm={3}>
              <Form.Group controlId="toCurrency">
                <Form.Label>To</Form.Label>
                <Form.Control
                  as="select"
                  value={toCurrency}
                  onChange={(e) => setToCurrency(e.target.value)}
                  className="custom-form-control"
                >
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="NGN">NGN</option>
                  {/* Add more currencies here */}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Button
            className="custom-btn"
            onClick={handleCurrencyConversion}
            block
            style={{
              backgroundColor: "#054b29",
              border: "1px solid #fff",
              borderRadius: "8px", // Rounded corners
              padding: "12px 16px",
              color: "#fff", // White text
              fontSize: "16px", // Adjust font size as needed
              cursor: "pointer", // Pointer cursor on hover
            }}
          >
            Convert Currency
          </Button>

          {convertedAmount && (
            <Card.Text className="mt-3">
              {amount} {fromCurrency} = {convertedAmount} {toCurrency}
            </Card.Text>
          )}

          {conversionError && <Card.Text className="text-danger">{conversionError}</Card.Text>}
        </Card.Body>
      </Card>

      {/* Footer */}
      <footer className="footer">
        <div className="text-center">
            <a href="/privacy" className="footer-link">Privacy</a> | 
            <a href="/terms" className="footer-link">Terms of Use</a> | 
            <a href="/help" className="footer-link">Help</a> |
            <a href="/advertising" className="footer-link">Advertising</a> |
            <a href="/user-agreement" className="footer-link">User Agreement</a>
        </div>
        <p className="text-center">© {new Date().getFullYear()} Inmail. Powered by InFlect Innovations. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WeatherCard;
