import React, { useState, useEffect } from 'react';
import { ListGroup, Button, Card, Spinner, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import logo from '../assets/img/main/logo.png';

const Sidebar = () => {
  const [liveScore, setLiveScore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLoginOpen = () => setShowLoginModal(true);
  const handleLoginClose = () => setShowLoginModal(false);

  // Fetch live score data (assuming a sports API)
  useEffect(() => {
    axios
      .get('https://api.football-data.org/v4/matches', {
        headers: { 'X-Auth-Token': 'c6c073ee4a26426b930600f16e62b0de' }, // Replace with your API Key
      })
      .then((response) => {
        setLiveScore(response.data.matches[0]); // Assuming you want the first match's score
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching live score', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="sidebar-container">
      <ListGroup>
        {/* Email Login Section */}
        <ListGroup.Item className="sidebar-item">
          <Button variant="primary" className="sidebar-button-first" onClick={handleLoginOpen}>
            Email Login
          </Button>
        </ListGroup.Item>

        {/* Free Email Registration Section */}
        <ListGroup.Item className="sidebar-item">
          <Button variant="success" className="sidebar-button-second">
            Own a Free Email Login
          </Button>
        </ListGroup.Item>

        {/* Live Score Section */}
        <ListGroup.Item className="sidebar-item live-score">
          <Card>
            <Card.Body>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                  <p>Loading live score...</p>
                </div>
              ) : liveScore ? (
                <>
                  <Card.Title>Live Score</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {liveScore.homeTeam.name} vs {liveScore.awayTeam.name}
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Score: </strong>
                    {liveScore.score.fullTime.homeTeam} - {liveScore.score.fullTime.awayTeam}
                  </Card.Text>
                </>
              ) : (
                <p>No live score available at the moment.</p>
              )}
            </Card.Body>
          </Card>
        </ListGroup.Item>
      </ListGroup>

      {/* Login Modal */}
      <Modal show={showLoginModal} onHide={handleLoginClose} centered>
        <Modal.Header closeButton>
          <div className="text-center mb-4">
            <img src={logo} alt="Logo" style={{ height: '30px' }} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>Login into your account</Modal.Title>
          <Form>
          <Form.Group controlId="formAccountName">
            <Form.Label>Account Name</Form.Label>
            <div className="d-flex">
              <Form.Control 
                type="text" 
                placeholder="Enter your name" 
                required 
                className="flex-grow-1" 
              />
              <Form.Select 
                className="ms-2 domain-select" // Add a custom class for styling
                required
              >
                <option value="example.com">inmail.ng</option>
                <option value="example.org">inmail.africa</option>
              </Form.Select>
            </div>
          </Form.Group>

            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter your password" required />
            </Form.Group>
            <div className="mt-3 d-flex justify-content-between">
              <Button style={{color:'#054b29'}} variant="link">Forgot Password?</Button>
              <Button style={{color:'#054b29'}} variant="link">Create an Account</Button>
            </div>
            <Button style={{backgroundColor:'#054b29', border: '1px solid #054b29'}} variant="primary" type="submit" className="mt-3 w-100">
              Login
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Sidebar;
