import React, { useState, useEffect } from "react";
import { Card, Col, Row, Form, InputGroup, Button } from "react-bootstrap";

const NewsGrid = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNews, setFilteredNews] = useState([]);
  const [rates, setRates] = useState({});

  const PAGE_SIZE = 15; // Number of items per page

  // Fetch News API
  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      const API_KEY = process.env.REACT_APP_NEWS_API_KEY;

      try {
        const response = await fetch(
          `https://api.currentsapi.services/v1/latest-news?page=${page}&page_size=${PAGE_SIZE}&apiKey=${API_KEY}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data && Array.isArray(data.news)) {
          setNewsData(data.news);
        } else {
          console.warn("Unexpected response structure:", data);
          setNewsData([]);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
        setNewsData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [page]);

  // Search Filter
  useEffect(() => {
    if (searchTerm) {
      const filtered = newsData.filter(
        (news) =>
          news.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          news.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredNews(filtered);
    } else {
      setFilteredNews(newsData);
    }
  }, [searchTerm, newsData]);

  // Fetch Currency Exchange Rates
  useEffect(() => {
    const fetchCurrencyRates = async () => {
      try {
        const response = await fetch(`https://open.er-api.com/v6/latest/USD`);
        const data = await response.json();
        if (data && data.rates) {
          setRates(data.rates);
        } else {
          console.error("Failed to fetch rates or invalid response:", data);
        }
      } catch (error) {
        console.error("Error fetching currency rates:", error);
      }
    };

    fetchCurrencyRates();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  // Format date based on user's locale
  const currentDate = new Intl.DateTimeFormat(navigator.language, {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(new Date());

  // Pagination: Slice filtered news data
  const startIndex = (page - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentPageNews = filteredNews.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredNews.length / PAGE_SIZE);

  return (
    <div className="news-container">
      {/* Date Display */}
      <div
        className="date-container"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          fontSize: "18px",
          fontWeight: "normal",
          color: "#fff",
          marginTop: "-300px",
        }}
      >
        Today is {currentDate}
      </div>

      {/* Search Bar */}
      <div
        className="search-bar-container"
        style={{
          textAlign: "center",
        marginBottom: "20px", // Reduced margin-bottom
        paddingTop: "0px", // Removed padding at the top
        }}
      >
        <InputGroup className="search-bar">
          <Form.Control
            type="text"
            placeholder="Search news..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              fontSize: "18px",
              borderRadius: "8px 0 0 8px",
              padding: "12px 16px",
            }}
          />
          <Button
            className="search-button"
            variant="outline-secondary"
            onClick={() => setSearchTerm("")}
            style={{
              backgroundColor: "#054b29",
              color: "white",
              borderRadius: "0 8px 8px 0",
              padding: "12px 16px",
              fontSize: "18px",
              border: "none",
            }}
          >
            Search
          </Button>
        </InputGroup>
      </div>

  {/* Currency Exchange Rates */}
    <div
      className="currency-rates"
      style={{
        display: "flex",
        justifyContent: "flex-start", // Align to the left
        alignItems: "flex-start", // Align items to the start (top of the container)
        marginBottom: "20px",
        padding: "10px",

      }}
    >
      {/* USD */}
      <div style={{ display: "flex", alignItems: "flex-start", marginRight: "15px" }}>
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
          $ = {rates["NGN"] ? `₦${rates["NGN"].toFixed(2)}` : "N/A"}
        </span>
      </div>

      {/* EUR */}
      <div style={{ display: "flex", alignItems: "flex-start", marginRight: "15px" }}>
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
          € ={" "}
          {rates["EUR"] && rates["NGN"]
            ? `₦${(rates["NGN"] / rates["EUR"]).toFixed(2)}`
            : "N/A"}
        </span>
      </div>

      {/* GBP */}
      <div style={{ display: "flex", alignItems: "flex-start", marginRight: "15px" }}>
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
          £ ={" "}
          {rates["GBP"] && rates["NGN"]
            ? `₦${(rates["NGN"] / rates["GBP"]).toFixed(2)}`
            : "N/A"}
        </span>
      </div>

      {/* CNY */}
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
          ¥ ={" "}
          {rates["CNY"] && rates["NGN"]
            ? `₦${(rates["NGN"] / rates["CNY"]).toFixed(2)}`
            : "N/A"}
        </span>
      </div>
    </div>


      <Row className="g-4">
        {currentPageNews.length > 0 ? (
          currentPageNews.map((news, index) => (
            <Col md={4} sm={6} xs={12} key={index}>
              <Card className="shadow-sm border-light rounded">
                <Card.Img
                  variant="top"
                  src={news.image || "https://via.placeholder.com/400x200"}
                  alt={news.title}
                  className="card-img-top rounded-top"
                  style={{ objectFit: "cover", height: "200px" }}
                />
                <Card.Body>
                  <Card.Title className="fw-bold text-dark">
                    {news.title.substring(0, 50)}
                    {news.title.length > 50 ? "..." : ""}
                  </Card.Title>
                  <Card.Text className="text-muted">
                    {news.description
                      ? `${news.description.substring(0, 100)}${
                          news.description.length > 100 ? "..." : ""
                        }`
                      : "No description available."}
                  </Card.Text>
                  <a
                    href={news.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-sm"
                    style={{
                      width: "100%",
                      backgroundColor: "#054b29",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Read More
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-center text-muted">No news available</p>
        )}
      </Row>

      {/* Pagination Controls */}
      <div
        className="pagination-container"
        style={{ marginTop: "20px", textAlign: "center" }}
      >
        <button
          className="btn btn-primary"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <span style={{ margin: "0 10px" }}>
          Page {page} of {totalPages}
        </span>
        <button
          className="btn btn-primary"
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default NewsGrid;
