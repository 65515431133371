import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import logo from '../assets/img/main/logo.png';
import './Header.css'; // Custom CSS for header responsive styles

const Header = ({ toggleDarkMode, darkMode }) => {
  return (
    <nav
      className={`navbar navbar-expand-lg ${darkMode ? 'navbar-dark bg-dark' : 'navbar-light bg-light'}`}
      style={{ position: 'sticky', top: '0', zIndex: '1020' }} 
    >
      <div className="container-fluid">
        {/* Logo */}
        <a className="navbar-brand" href="#">
          <img src={logo} alt="Logo" style={{ height: '30px' }} />
        </a>

        {/* Mobile toggle button */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar items */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto d-flex align-items-center">
            {/* Menu Links */}
            <li className="nav-item ms-3" >
              <a className="nav-link active" aria-current="page" href="#home">
                Mail
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                Currency Converter
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Weather
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="allProductsDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                All Products
              </a>
              <div className="dropdown-menu custom-mega-dropdown" aria-labelledby="allProductsDropdown">
                <div className="container">
                  <div className="row">
                    <div className="col-4">
                      <h6 className="dropdown-header">Category 1</h6>
                      <a href="#product1" className="dropdown-item">Product 1</a>
                      <a href="#product2" className="dropdown-item">Product 2</a>
                    </div>
                    <div className="col-4">
                      <h6 className="dropdown-header">Category 2</h6>
                      <a href="#product3" className="dropdown-item">Product 3</a>
                      <a href="#product4" className="dropdown-item">Product 4</a>
                    </div>
                    <div className="col-4">
                      <h6 className="dropdown-header">Category 3</h6>
                      <a href="#product5" className="dropdown-item">Product 5</a>
                      <a href="#product6" className="dropdown-item">Product 6</a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          {/* Login button and Dark Mode toggle */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <button
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#054b29",
                border: "1px solid #5ab872",
                backgroundColor: "#5ab872",
              }}
               className="btn btn-outline-primary me-2" type="button">
                Login
              </button>
            </li>
            <li className="nav-item">
              <div className="d-flex align-items-center">
                <i
                  className={`fas fa-sun sun-icon ${darkMode ? 'd-none' : ''}`}
                  onClick={toggleDarkMode}
                  style={{ cursor: 'pointer' }}
                ></i>
                <i
                  className={`fas fa-moon moon-icon ${darkMode ? '' : 'd-none'}`}
                  onClick={toggleDarkMode}
                  style={{ cursor: 'pointer' }}
                ></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
